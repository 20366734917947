import React, { useEffect, useState } from 'react';

import { getNameParts, parseName } from '@rippling/utils';
import { fetchUserData } from '@rippling/utils/clearbit';
import { getCountryIso2 } from '@rippling/utils/countryUtils';
import { formatTimestamp } from '@rippling/utils/datetimeUtils';
import { getMappedTimeZone } from '@rippling/utils/demotime/helpers';
import { getGASessionId } from '@rippling/utils/gaUtils';
import classnames from 'classnames';
import { Formik } from 'formik';
import ky from 'ky';
import _throttle from 'lodash.throttle';
import { withRouter } from 'next/router';
import Cookies from 'universal-cookie';

import UserContext from '../../../context/UserContext';
import {
  dateToDateStr,
  get30Days,
  getXHoursFromNow
} from '../../../helpers/date-helpers';
import {
  formDataToObject,
  getCurrentFormFillLandingPageUrl,
  getDemoDateTime,
  getFormIdentifier,
  getVisitorsExperiments
} from '../../../helpers/form-helpers';
import { postToOpenpriseProxy } from '../../../lib/openprise';
import { getUrlParam, recursiveMap } from '../../helpers';

const emailRegex = /^[a-zA-Z0-9.!#$%&‘*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]{2,}$/;
const standardEmail = /^(?!.+@(gmail|google|yahoo|outlook|hotmail|msn|protonmail|proton)\..+)(.+@.+\..+)$/;

const emailPreferencesObj = {
  Benefits__c: 'false',
  Directmail_Email_Opt_In__c: 'false',
  Email__c: '',
  Email_Type__c: '',
  Finance__c: 'false',
  Global_Payroll__c: 'false',
  HR__c: 'false',
  In_Person_Event_invites__c: 'false',
  IT_Application_and_Device_Management__c: 'false',
  New_Feature_Announcements__c: 'false',
  Newsletter__c: 'false',
  Opt_Out_of_all_Communications__c: 'false',
  Product_Recommendations__c: 'false',
  Snooze_Duration__c: '',
  Surveys__c: 'false',
  Talent__c: 'false',
  Tips_Content__c: 'false',
  Webinars_Invites__c: 'false',
};

// Render all the hidden inputs
const HiddenFields = ({
  formType, hiddenInputs, initialLandingPage, referrer,
}) => {
  // Convert hidden fields object to array
  const convertHiddenFieldsObject = () => {
    if (!hiddenInputs) {
      return [];
    }

    return Object.keys(hiddenInputs).map((key) => {
      return {
        key,
        value: hiddenInputs[key],
      };
    });
  };

  const fields = [
    ...convertHiddenFieldsObject(),
    {
      key: 'Form_Category',
      value: formType,
    },
    {
      key: 'referrer',
      value: referrer,
    },
    {
      key: 'initial_landing_page',
      value: initialLandingPage,
    },
  ];

  return fields.map(({ key, value }) => {
    return (
      <input
        key={key}
        name={key}
        type="hidden"
        value={value}
      />
    );
  });
};

function mapFormValuesToDemotime({
  business_email,
  Country,
  Country_Dialing_Code__c,
  user_company,
  user_email,
  user_employees,
  user_lead_source,
  user_name,
  user_phone,
  who_referred_you,
}) {
  const urlParams = new URLSearchParams(window.location.search);
  const maybeDebugDemotime = urlParams.get('debug');
  const debug = maybeDebugDemotime === 'true';

  const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const mappedClientTimeZone = getMappedTimeZone(clientTimeZone);

  return {
    Company_Name: user_company,
    debug,
    Full_Name: user_name,
    Have_you_run_payroll_this_year: user_employees > 0 ? 'Yes' : 'No',
    Headquarters: Country,
    How_did_you_hear_about_us: [user_lead_source, who_referred_you].filter(Boolean).join(', '),
    mappedClientTimeZone,
    Number_of_employees: user_employees,
    Phone: [Country_Dialing_Code__c, user_phone].filter(Boolean).join(' '),
    work_email_address: business_email || user_email,
  };
}

function createDemotimeQueryStringFromObject(obj) {
  const params = new URLSearchParams(obj);
  const queryString = params.toString();

  // https://rippling.atlassian.net/browse/WWW-821
  return queryString.replaceAll('+', '%20');
}

const normalizeArray = (arr) => {
  return arr.flatMap((item) => {
    return typeof item === 'string' ? item.split(', ') : item;
  });
};

const processPreferenceCenterData = (arrays, dataObject) => {
  const email = arrays[0];
  const emailSnooze = arrays[3];
  const emailType = arrays[6];
  const allValues = normalizeArray(arrays);
  for (const value of allValues) {
    if (value === email) {
      dataObject['Email__c'] = email; // Custom action
    }

    if (value === emailSnooze) {
      dataObject['Snooze_Duration__c'] = emailSnooze;
    }

    if (value === emailType) {
      dataObject['Email_Type__c'] = emailType;
    }

    // Regular processing for remaining values
    if (Object.prototype.hasOwnProperty.call(dataObject, value)) {
      dataObject[value] = 'true';
    }
  }

  return dataObject;
};

const validateEmail = (email) => {
  if (!email?.length > 3) {
    return false;
  }

  return emailRegex.test(email);
};

const isFastTrackEligible = async (payload) => {
  try {
    const res = await fetch(
      `${process.env.NEXT_PUBLIC_APP_HUB_API_BASE_URL}/hub/api/fast_track/validate_data_from_fast_track_marketing_form/`,
      {
        body: JSON.stringify(payload),
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
      }
    );

    const json = await res.json();

    return json.decision === 'PENDING';
  } catch {
    // Log
  }

  return false;
};

const fetchFormDataWithCb = async (email, cb) => {
  if (!validateEmail(email)) {
    cb(null);

    return;
  }

  const cbData = await fetchUserData({ email });

  if (!cbData) {
    cb(null);

    return;
  }

  const formData = {
    Country: cbData.country,
    user_company: cbData.name,
    user_email: email,
  };

  cb(formData);
};

const getClearbitData = () => {
  const cbReveal =
    (window.dataLayer ?? []).find((item) => item?.reveal)?.reveal ?? {};

  return {
    company: cbReveal.company ?? '',
    ip: cbReveal.ip ?? '',
  };
};

const throttledFetchUserFormData = _throttle(fetchFormDataWithCb, 500, { trailing: true });

const getFields = (
  data,
  fields,
  touched,
  steps,
  dependencies,
  isClearbitForm
) => {
  const cookies = new Cookies();
  const children = data.innerBlocks || [];

  for (const child of children) {
    if (child.attributes.alias === 'Step') {
      const { dependency } = child.attributes.data;

      if (dependency) {
        dependencies.push(dependency);
      } else {
        dependencies.push(null);
      }

      steps.push([]);
    }

    if (
      child.name === 'field' ||
      child.name === 'field2' ||
      child.name === 'select' ||
      child.name === 'radios'
    ) {
      const childAttrName = child.attributes.name;
      const cookieName = childAttrName === 'business_email' ? 'user_email' : childAttrName;

      const cookieValue = cookies.get(cookieName);
      const clearbitCookieValue = cookies.get(`clearbit_${childAttrName}`);

      // We only want to use clearbit cookies as defaults if it's a clearbit form
      // and we don't want to use our standard form cookie values to prefill clearbiti forms
      if (isClearbitForm && clearbitCookieValue) {
        fields[childAttrName] = clearbitCookieValue;
        touched[childAttrName] = true;

        cookies.remove(`clearbit_${childAttrName}`);
      } else if (!isClearbitForm && cookieValue) {
        fields[childAttrName] = cookieValue;
        touched[childAttrName] = true;
      } else {
        fields[childAttrName] = '';
      }

      const step = steps.at(-1);
      if (step) {
        step.push(childAttrName);
      }
    }

    getFields(child, fields, touched, steps, dependencies, isClearbitForm);
  }

  return [
    fields,
    touched,
    steps,
    dependencies,
  ];
};
const validate = (values, step, isItFastTrack) => {
  const errors = {};

  let valuesToCheck = {};
  if (step) {
    for (const field of step) {
      valuesToCheck[field] = values[field];
    }
  } else {
    valuesToCheck = values;
  }

  for (const key in valuesToCheck) {
    // eslint-disable-next-line no-prototype-builtins
    if (values.hasOwnProperty(key)) {
      let val = values[key];

      switch (key) {
        case 'user_name': {
          if (!val?.trim() || val.length <= 0) {
            errors.user_name = 'Invalid user name';
          }

          const nameParts = val.trim().split(/\s+/);

          if (isItFastTrack && nameParts.length < 2) {
            errors.user_name = 'Please provide both your first and last name.';
          }
          break;
        }
        case 'first_name': {
          if (!val?.trim() || val.length <= 0) {
            errors.first_name = 'Invalid first name';
          }
          break;
        }
        case 'last_name': {
          if (!val?.trim() || val.length <= 0) {
            errors.last_name = 'Invalid last name';
          }
          break;
        }
        case 'user_email': {
          if (!emailRegex.test(val)) {
            errors.user_email = 'Invalid user email';
          }
          break;
        }
        case 'user_company': {
          if (!val?.trim() || val.length <= 0) {
            errors.user_company = 'Invalid user company';
          }
          break;
        }
        case 'user_employees': {
          val = Number.parseInt(val);
          if (!val || val <= 0 || Number.isNaN(val)) {
            errors.user_employees = 'Invalid user employees';
          }
          break;
        }
        case 'user_lead_source': {
          if (!val || val === '') {
            errors.user_lead_source = 'Invalid lead source';
          }
          break;
        }
        case 'user_lead_source_text': {
          if (!val?.trim() || val.length <= 0) {
            errors.user_lead_source_text = 'Invalid lead source';
          }
          break;
        }
        case 'contact_reason': {
          if (!val || val === '') {
            errors.contact_reason = 'Invalid contact reason';
          }
          break;
        }
        case 'company_offer_insurance': {
          if (!val || val === '') {
            errors.company_offer_insurance =
              'Invalid company issurance offer answer';
          }
          break;
        }
        case 'Last_Lead_Origin__c': {
          if (!val || val === '') {
            errors.Last_Lead_Origin__c = 'Please select a session';
          }
          break;
        }
        case 'business_email': {
          if (!standardEmail.test(val)) {
            errors.business_email = 'Please use your business email';
          }
          break;
        }
        case 'insurance_date': {
          if (valuesToCheck.company_offer_insurance !== 'Yes') {
            break;
          }

          let month;
          let date;
          let year;

          // For Safari and browsers that don't support date field
          if (/\//.test(val)) {
            const data = val.split('/');
            month = data[0];
            date = data[1];
            year = data[2];
          } else {
            const data = val.split('-');
            year = data[0];
            month = data[1];
            date = data[2];
          }

          const now = new Date();
          const currentYear = now.getFullYear();
          const currentMonth = now.getMonth() + 1;
          const currentDate = now.getDate();

          year = Number.parseInt(year);
          month = Number.parseInt(month);
          date = Number.parseInt(date);

          if (!year || !month || !date) {
            errors.insurance_date = 'Invalid date. Expected format: mm/dd/yyyy';
          } else if (
            year < currentYear ||
            year === currentYear && month < currentMonth ||
            year === currentYear &&
              month === currentMonth &&
              date <= currentDate
          ) {
            errors.insurance_date = 'Date already passed';
          } else if (year > 2100) {
            errors.insurance_date = 'Date not covered';
          }

          break;
        }
        case 'user_previous_payroll': {
          const userEmployees = valuesToCheck.user_employees
            ? Number.parseInt(valuesToCheck.user_employees)
            : 0;
          if ((!val || val === '') && userEmployees <= 10) {
            errors.user_previous_payroll = 'Invalid previous payroll answer';
          }
          break;
        }
        case 'user_phone': {
          if (!val || val === '' || val.length < 9) {
            errors.user_phone = 'Invalid phone number';
          }
          break;
        }
        case 'schedule_demo_datetime': {
          if (!val || val.length <= 0) {
            errors.schedule_demo_datetime = 'Please select a date';
          } else if (val.getHours() < 6 || val.getHours() > 17) {
            errors.schedule_demo_datetime = 'Please select a preferred time';
          }
          break;
        }
        case 'Agree_to_policy': {
          if (!val || val.length <= 0) {
            errors.Agree_to_policy = 'You must agree to continue';
          }
          break;
        }
        case 'Attending_SHRM23': {
          if (!val || val.length <= 0) {
            errors.Attending_SHRM23 = 'You must be registered to be eligible';
          }
          break;
        }
        case 'organizationType': {
          if (!val || val.length <= 0) {
            errors.organizationType = 'No organization type selected';
          }
          break;
        }
        case 'user_gift_preference': {
          if (!val || val.length <= 0) {
            errors.user_gift_preference = 'No gift card selected';
          }
          break;
        }
        case 'Demo_PreQual_Current_Payroll_Provider__c': {
          if (!val || val.length <= 0) {
            errors.Demo_PreQual_Current_Payroll_Provider__c =
              'No payroll provider specified';
          }
          break;
        }
        case 'Demo_PreQual_Num_and_Type_of_Employees__c': {
          if (!val || val.length <= 0) {
            errors.Demo_PreQual_Num_and_Type_of_Employees__c =
              'No number of employees specified';
          }
          break;
        }
        case 'Demo_PreQual_Areas_of_Interest__c': {
          if (!val || val.length <= 0) {
            errors.Demo_PreQual_Areas_of_Interest__c =
              'No pain points specified';
          }
          break;
        }
        case 'Country': {
          if (!val?.trim()) {
            errors.Country = 'Please select a country';
          }
          break;
        }
        case 'who_referred_you': {
          var campaign = getUrlParam('utm_campaign');
          if ((
            campaign === 'partner-referral-accountant' ||
            campaign === 'partner-referral-broker'
          ) && (!val || val === '')) {
            errors.who_referred_you = 'Please let us know who referred you';
          }
          break;
        }
        case 'user_interest': {
          if (!val) {
            errors.user_interest = 'Please select a interest';
          }
          break;
        }
        case 'user_availability': {
          if (!val?.trim()) {
            errors.user_availability = 'Invalid availability';
          }
          break;
        }
        case 'user_shipping_address': {
          if (!val?.trim()) {
            errors.user_shipping_address = 'Invalid shippling address';
          }
          break;
        }
        case 'software_used_the_most': {
          if (!val) {
            errors.software_used_the_most = 'Please select one';
          }
          break;
        }
        case 'current_hr_software_feedback': {
          if (!val?.trim()) {
            errors.current_hr_software_feedback = 'Please select one';
          }
          break;
        }
        case 'user_title': {
          if (!val?.trim()) {
            errors.user_title = 'Please add your title';
          }
          break;
        }
        default:
      }
    }
  }

  if (values.user_lead_source_text) {
    delete errors.user_lead_source;
  }
  if (values.user_lead_source) {
    delete errors.user_lead_source_text;
  }

  return errors;
};

const googleAdwordsTracking = () => {
  if (window.google_trackConversion) {
    window.google_trackConversion({
      google_conversion_color: 'ffffff',
      google_conversion_format: '3',
      google_conversion_id: 794_594_069,
      google_conversion_label: 'odM0CJb_sIkBEJWW8voC',
      google_conversion_language: 'en',
      google_remarketing_only: false,
    });
  }
};

const firePPCSitePixels = () => {
  // trigger capterra tracking.
  const capterra_prefix =
    document.location.protocol === 'https:'
      ? 'https://ct.capterra.com'
      : 'http://ct.capterra.com';

  const capterraTrackers = {
    Australia: ['2300254', '9e3a062ef763eb52ac9807a9004c126b'],
    Canada: ['2300252', 'c68623610552a6dd44ddcd7cd6ce764b'],
    France: ['2300935', 'ed46e90587e8dc86b46d3da2e380198d'],
    IT: ['2346686', '9fcc938e1b292fe589dc1639a825316d'],
    NetherlandsBelgium: ['2330553', '40afef419c0b5d980d432cc14ba54a0a'],
    Spend: ['2346685', '7051778c6ce3535d27591d6fbddc3a89'],
    UK: ['2249605', 'd6b52d8415f4856d863b576454387b5b'],
    US: ['2119234', '5d388caf8dac5a4cabc7669880b61e59'],
  };

  for (const [capterra_vid, capterra_vkey] of Object.values(capterraTrackers)) {
    (function() {
      const ct = document.createElement('script');
      ct.type = 'text/javascript';
      ct.async = true;
      ct.src = `${capterra_prefix}/capterra_tracker.js?vid=${capterra_vid}&vkey=${capterra_vkey}`;
      const s = document.querySelectorAll('script')[0];
      s.parentNode.insertBefore(ct, s);
    })();
  }

  // trigger crozdesk tracking.
  (function() {
    const cdx = document.createElement('script');
    cdx.type = 'text/javascript';
    cdx.async = true;
    cdx.src = `${
      document.location.protocol === 'https:' ? 'https://' : 'http://'
    }trk.crozdesk.com/FC4ZLTASMywvbywebrSQ`;
    const s = document.querySelectorAll('script')[0];
    s.parentNode.insertBefore(cdx, s);
  })();
};

const captureUserEmail = (cookies, values) => {
  if (values?.user_email || values?.business_email) {
    cookies.set('user_email', values?.business_email || values?.user_email, {
      domain: window.location.hostname,
      expires: getXHoursFromNow(1),
      path: '/',
    });
  }
};

const onError = () => {
  alert('The form submission failed. Please refresh the page and try again or contact someone at resources@rippling.com');
};

const formDataKeyToFieldNameMappings = {
  form_category: 'Form_Category',
  form_type: 'Form_Type__c',
  formProgress_stage1: 'formProgress_stage1',
  formProgress_stage2: 'formProgress_stage2',
  formProgress_stage3: 'formProgress_stage3',
  inboundTestVariant: 'inboundTestVariant',
  initial_landing_page: 'initial_landing_page',
  p_name: 'p_name',
  Product_Updates_Opt_Out__c: 'Product_Updates_Opt_Out__c',
  referrer: 'referrer',
  user_company_type: 'company_type',
  utility: 'utility',
  video_tour: 'video_tour',
  Webinar_Registration_Date__c: 'eventDate',
};

const getHiddenFieldValues = ({ formRef }) => {
  return Object.keys(formDataKeyToFieldNameMappings).reduce((values, key) => {
    const fieldName = formDataKeyToFieldNameMappings[key];
    const element = formRef.current?.querySelector(`input[name="${fieldName}"]`);

    values[key] = element ? element.value : undefined;

    return values;
  }, {});
};

const submissionHandler = async ({
  customOnSuccess,
  customOnSuccess2,
  enableChiliPiperDemoFlow,
  enableDemotimeFlow,
  enableFastTrackDemoFlow,
  enableGiftOptInDemoFlow,
  enablePreferenceCenterFlow,
  enableUnsubscribeEmailFlow,
  formRef,
  googleClientId,
  locale,
  redirectTo,
  router,
  setIsComplete,
  setShowDemotimeModal,
  turnOffThankYouPageRedirect,
  values,
  whitelistScheduleDate,
}) => {
  googleAdwordsTracking();
  firePPCSitePixels();

  const cookies = new Cookies();
  const routerPath = router.asPath;
  captureUserEmail(cookies, values);
  const isMeetupPage = /^\/meetups/.test(routerPath);
  const isXeroconReceptionPage = /^\/xerocon-reception/.test(routerPath);

  const routerOptions = {};

  if (isMeetupPage || isXeroconReceptionPage) {
    try {
      await ky.post(`${window.location.origin}/api/www-vercel-add-google-sheets-form-submission`, {
        json: [
          values.user_name,
          values.user_email,
          values.user_company,
          window.location.pathname.replace(
            isMeetupPage ? '/meetups/' : '/xerocon-reception/',
            ''
          ),
          values.is_rippling_customer || 'No',
        ],
      });

      if (setIsComplete) {
        setIsComplete();
      }
    } catch (error) {
      console.log('e:', error);
    }

    return;
  }

  const {
    business_email,
    company_offer_insurance,
    contact_reason,
    current_challenges_c,
    first_name,
    insurance_date,
    is_new_or_existing_customer,
    last_name,
    Likely_Global_Fit__c,
    Num_Years_as_Rippling_User__c,
    organizationType,
    Product_Interest_3P__c,
    schedule_demo_datetime,
    schedule_demo_timezone,
    State,
    ur_products,
    ur_topics,
    user_company,
    user_email,
    user_employees,
    user_gift_preference,
    user_lead_source,
    user_name,
    user_phone,
    user_previous_payroll,
    user_title,
    Wavemakers_Application_Reason__c,
    who_referred_you,
  } = values;

  let {
    Append_to_Comments__c,
    beverage_of_choice,
    code_repository,
    current_hr_software_feedback,
    customer_service_platform,
    gift_choice,
    hr_satisfaction_rate,
    rippling_representative,
    software_used_the_most,
    user_availability,
    user_email_preferences_gifts,
    user_email_preferences_products,
    user_email_preferences_snooze,
    user_email_preferences_type,
    user_email_preferences_unsubscribe,
    user_interest,
    user_shipping_address,
  } = values;

  let chiliPiperFlowData = { didMeetingAvailabiltyLoad: false };
  let demotimeFlowPayload = { didMeetingAvailabiltyLoad: false };

  const hiddenFieldValues = getHiddenFieldValues({ formRef });

  // Form data to be shared across different form submission flows
  const bodyFormData = new FormData();

  const addToFormData = (key, value) => {
    if (value !== undefined) {
      bodyFormData.append(key, value);
    }
  };

  const addHiddenFieldsToFormData = () => {
    addToFormData('Form_Type__c', hiddenFieldValues.form_type || undefined);
    addToFormData('inboundTestVariant', hiddenFieldValues.inboundTestVariant || undefined);
    addToFormData('Landing_Page__c', hiddenFieldValues.initial_landing_page || undefined);
    addToFormData('p_name', hiddenFieldValues.p_name || undefined);
    addToFormData('Product_Updates_Opt_Out__c', hiddenFieldValues.Product_Updates_Opt_Out__c || undefined);
    addToFormData('referrer_url__c', hiddenFieldValues.referrer || undefined);
    addToFormData('Company_Type_Detail__c', hiddenFieldValues.user_company_type || undefined);
    addToFormData('utility', hiddenFieldValues.utility || undefined);
    addToFormData('video_tour', hiddenFieldValues.video_tour || undefined);
    addToFormData('Webinar_Registration_Date__c', hiddenFieldValues.Webinar_Registration_Date__c
      ? dateToDateStr(hiddenFieldValues.Webinar_Registration_Date__c.value)
      : undefined);
    addToFormData('formProgress_stage1', hiddenFieldValues.formProgress_stage1 || undefined);
    addToFormData('formProgress_stage2', hiddenFieldValues.formProgress_stage2 || undefined);
    addToFormData('formProgress_stage3', hiddenFieldValues.formProgress_stage3 || undefined);
  };

  // Add hidden fields to form data
  addHiddenFieldsToFormData();

  // eslint-disable-next-line prefer-const
  let Last_Lead_Origin__c = formRef.current?.querySelector('[name="Last_Lead_Origin__c"]') || undefined;

  if (enableUnsubscribeEmailFlow) {
    try {
      await postToOpenpriseProxy({
        bot_id: '7416',
        formData: {
          Email__c: user_email,
          Email_Type__c: cookies.get('email-type'),
          ...formDataToObject(bodyFormData),
        },
        OpenpriseEnabled: false,
        version: 'old',
      });

      window.dataLayer.push({
        event: 'Form Submit - Unsubscribe',
        formWorkEmail: user_email,
      });

      if (customOnSuccess2) {
        new Function('values', customOnSuccess2)({
          cookies,
          formData,
          router,
        });
      }
    } catch (error) {
      console.error('Openprise submission error:', error);
    }

    return false;
  }

  if (enablePreferenceCenterFlow) {
    try {
      const emailType = cookies.get('email-type');
      const preferenceCenterPayload = processPreferenceCenterData([
        user_email,
        user_email_preferences_gifts,
        user_email_preferences_products,
        user_email_preferences_snooze,
        user_email_preferences_type,
        user_email_preferences_unsubscribe,
        emailType,
      ], emailPreferencesObj);

      await postToOpenpriseProxy({
        bot_id: '7408',
        formData: {
          ...preferenceCenterPayload,
          ...formDataToObject(bodyFormData),
        },
        OpenpriseEnabled: false,
        version: 'old',
      });

      window.dataLayer.push({
        event: 'Form Submit - Preference Center',
        formWorkEmail: user_email,
      });

      if (customOnSuccess2) {
        new Function('values', customOnSuccess2)({
          cookies,
          formData,
          router,
        });
      }
    } catch (error) {
      console.error('Openprise submission error:', error);
    }

    return false;
  }

  if (enableFastTrackDemoFlow) {
    const { firstName, lastName } = getNameParts({
      firstName: first_name,
      fullName: user_name,
      lastName: last_name,
    });

    const email = values.business_email || values.user_email;
    let country = values.Country;
    const countryCode = values.Country_Dialing_Code__c;
    const countryCodeNumber = countryCode?.split('+')[1];
    country = getCountryIso2(country);
    const employeeSize = Number.parseInt(user_employees, 10);

    const fastTrackFormTypes = {
      'it-trial': 'IT_TRIAL',
      payroll: 'PAYROLL',
      spend: 'SPEND',
    };

    let fastTrackFormType = fastTrackFormTypes.payroll;

    if (formRef.current) {
      const className = [...formRef.current.classList].find((name) =>
        name.startsWith('is-fast-track-demo-request-form-type--'));

      if (className) {
        const extractedType = className.split('--')[1];
        fastTrackFormType = fastTrackFormTypes[extractedType] || fastTrackFormType;
      }
    }

    const fastTrackPayload = {
      companyHQCountry: country,
      companyName: user_company,
      countryCode: countryCodeNumber,
      email: email,
      firstName,
      hasRunPayroll: user_previous_payroll === 'True',
      lastName,
      nationalNumber: user_phone,
      numberOfPeople: employeeSize,
      questionnaireType: fastTrackFormType,
    };

    let fastTrackEligible;
    if (fastTrackFormType === 'SPEND') {
      if (country === 'US' && standardEmail.test(email)) {
        fastTrackEligible = true;
      } else {
        fastTrackEligible = false;
      }
    } else {
      fastTrackEligible = await isFastTrackEligible(fastTrackPayload);
    }

    if (fastTrackEligible) {
      const fastTrackStorePayload = {
        data: { ...fastTrackPayload },
        questionnaireType: fastTrackFormType,
      };

      //set sessionStorage
      sessionStorage.setItem(
        'fasttrack-questionnaire',
        JSON.stringify(fastTrackStorePayload)
      );

      //change LLO
      if (fastTrackFormType === 'IT_TRIAL') {
        Last_Lead_Origin__c.value = '701Ud00000HIwF4IAL';
      } else if (fastTrackFormType === 'SPEND') {
        Last_Lead_Origin__c.value = '701Ud00000J2jkiIAB';
      } else {
        Last_Lead_Origin__c.value = 'Other_CORE_CAN_701Ud00000CQyjBIAT';
      }

      //redirect to questionaire
      if (fastTrackFormType === 'SPEND') {
        redirectTo = '/spend-management/fast-track/get-started';
      } else {
        redirectTo = '/fasttrack/questionnaire';
      }

      routerOptions.locale = '';

      // Disable Demotime flow if FastTrack eligible and DemoTime eligible
      if (enableDemotimeFlow) {
        enableDemotimeFlow = false;
      }
    }
  }

  const convertTimestampToISO8601 = (timestamp) => {
    const date = new Date(timestamp);

    return date.toISOString();
  };

  if (enableChiliPiperDemoFlow) {
    const meetingTypes = [
      {
        maxEmployees: 30,
        minEmployees: 0,
        type: 'see-rippling',
      },
      {
        maxEmployees: 175,
        minEmployees: 31,
        type: 'see-rippling-mm',
      },
      {
        maxEmployees: 1000,
        minEmployees: 176,
        type: 'see-rippling-ent',
      },
    ];

    function getChilipiperMeetingType(numEmployees) {
      const minEmployees = Math.min(...meetingTypes.map(({ minEmployees }) => minEmployees));
      const maxEmployees = Math.max(...meetingTypes.map(({ maxEmployees }) => maxEmployees));

      const isEmployeeSizeInRange = numEmployees >= minEmployees && numEmployees <= maxEmployees;

      if (!isEmployeeSizeInRange) {
        return null;
      }

      const meetingType = meetingTypes.find(({ maxEmployees, minEmployees }) => {
        return numEmployees >= minEmployees && numEmployees <= maxEmployees;
      });

      return meetingType.type;
    }

    const chiliPiperEvents = [];
    const country = values.Country;
    const allowedCountries = ['United States'];
    const employeeSize = Number.parseInt(user_employees, 10);
    const meetingType = getChilipiperMeetingType(employeeSize);

    let isChiliPiperEligible = false;

    if (allowedCountries.includes(country) && meetingType !== null) {
      isChiliPiperEligible = true;
    }

    // Redirect to standard thank you page if chili-enabled but user ineligible.
    if (!isChiliPiperEligible) {
      redirectTo = '/thank-you';
    }

    if (isChiliPiperEligible) {
      const trustedOrigin = 'https://rippling.chilipiper.com';

      const INACTIVITY_TIMEOUT = 10 * 60 * 1000;
      const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      let chiliPiperModal;
      let inactivityTimer;
      let resolvePromise;

      const handleChilipiperMessages = (event) => {
        if (event.origin !== trustedOrigin) {
          console.warn(
            'Received message from an untrusted origin:',
            event.origin
          );

          return;
        }

        resetInactivityTimer();

        chiliPiperEvents.push(event.data);

        if (event.data.action === 'availability-loaded') {
          chiliPiperModal = document.querySelector('.chilipiper-popup');

          chiliPiperFlowData = {
            ...chiliPiperFlowData,
            didMeetingAvailabiltyLoad: true,
          };
        }

        if (event.data.action === 'timeslot-selected') {
          const { duration, timezone } = event.data.args;

          chiliPiperFlowData = {
            ...chiliPiperFlowData,
            ActivityDuration: duration,
            ActivityTimeZone: timezone,
            clientTimeZone,
          };
        }

        if (event.data.action === 'booking-confirmed') {
          const {
            assigneeId, eventId, routeId, slot,
          } = event.data.args;
          const { end, start } = slot;

          chiliPiperFlowData = {
            ...chiliPiperFlowData,
            ActivityDate: convertTimestampToISO8601(start),
            ActivityDateTime: convertTimestampToISO8601(start),
            EventEndDateTime: convertTimestampToISO8601(end),
            EventOwnerId: assigneeId,
            EventStartDateTime: convertTimestampToISO8601(start),
            Queue_Name_CP__c: eventId,
            Router_Name_CP__c: routeId,
          };
        }
      };

      const resetInactivityTimer = () => {
        if (inactivityTimer) {
          clearTimeout(inactivityTimer);
        }
        inactivityTimer = setTimeout(() => {
          console.warn('No activity detected. Resolving promise due to inactivity.');
          resolveChiliPiperPromise();

          if (chiliPiperModal) {
            chiliPiperModal.remove();
          }
        }, INACTIVITY_TIMEOUT);
      };

      const resolveChiliPiperPromise = () => {
        window.removeEventListener('message', handleChilipiperMessages);
        clearTimeout(inactivityTimer);

        chiliPiperFlowData = {
          ...chiliPiperFlowData,
          chiliPiperEvents: JSON.stringify(chiliPiperEvents),
        };

        routerOptions.locale = '';

        if (resolvePromise) {
          resolvePromise();
        }
      };

      const submitChiliPiperForm = () => {
        return new Promise((resolve, reject) => {
          resolvePromise = resolve;

          const { firstName, lastName } = parseName(values.user_name);

          /* global ChiliPiper */
          ChiliPiper.submit('rippling', 'web_test', {
            lead: {
              ...values,
              business_email: values.user_email || values.business_email,
              FirstName: firstName,
              LastName: lastName,
            },
            map: true,
            onClose: resolveChiliPiperPromise,
            onError: reject,
            onSuccess: resolveChiliPiperPromise,
            type: meetingType,
          });

          resetInactivityTimer();
        });
      };

      const executeChiliPiperFlow = async () => {
        window.addEventListener('message', handleChilipiperMessages);

        try {
          await submitChiliPiperForm();
        } catch (error) {
          console.error('Error during ChiliPiper submission:', error);
        }
      };

      await executeChiliPiperFlow();
    }
  }

  let schedule_demo_date;
  let schedule_demo_time;

  if (schedule_demo_datetime) {
    const zeroify = (num) => `0${num}`.slice(-2);

    [schedule_demo_date, schedule_demo_time] = [`${zeroify(schedule_demo_datetime.getMonth() + 1)}/${zeroify(schedule_demo_datetime.getDate())}/${schedule_demo_datetime.getFullYear()}`, `${zeroify(schedule_demo_datetime.getHours())}:${zeroify(schedule_demo_datetime.getMinutes())}`];
  }

  //for talent signal waitlist form
  if (code_repository || customer_service_platform) {
    Append_to_Comments__c = `What system do you use to create and manage code? ${
      code_repository || ''
    }
    What system do you use to handle customer support interactions? ${customer_service_platform || ''}
    For which function are you interested in Talent Signal? ${user_interest || ''}`;
  }

  //gift opt-in / congrats forms
  if (
    user_shipping_address ||
    rippling_representative ||
    user_availability ||
    beverage_of_choice ||
    gift_choice
  ) {
    const commentSections = [];

    if (user_shipping_address) {
      commentSections.push(`Shipping address including city & zip code? ${user_shipping_address}`);
    }
    if (rippling_representative) {
      commentSections.push(`Name of Rippling representative? ${rippling_representative}`);
    }
    if (beverage_of_choice) {
      commentSections.push(`Beverage of choice? ${beverage_of_choice}`);
    }
    if (user_availability) {
      commentSections.push(`Availability for an intro call with Rippling? ${user_availability}`);
    }
    if (gift_choice) {
      commentSections.push(`Gift choice (fulfill via Postal post-demo): ${gift_choice}`);
    }

    Append_to_Comments__c = commentSections.join('\n');
  }

  // hr deserves better form
  if (
    software_used_the_most ||
    hr_satisfaction_rate ||
    current_hr_software_feedback
  ) {
    Append_to_Comments__c = `Which software do you use the most? ${software_used_the_most || ''} 
      On a scale of 1 to 5, how satisfied are you with your current HR software? ${hr_satisfaction_rate || ''} 
      What do you wish you could change about your current HR software? ${current_hr_software_feedback || ''}
    `;
  }

  if (enableGiftOptInDemoFlow) {
    const sendZapierData = async () => {
      const timestamp = Date.now();
      const formattedDate = formatTimestamp(timestamp);
      const zapierPayload = {
        beverage_choice: values.beverage_of_choice,
        call_availability: values.user_availability,
        company: values.user_company,
        email_address: values.user_email,
        first_name: values.first_name,
        form_type: hiddenFieldValues.form_type || undefined,
        last_name: values.last_name,
        llo: Last_Lead_Origin__c.value || undefined,
        phone_number: values.user_phone,
        referrer: hiddenFieldValues.referrer || undefined,
        rippling_rep: values.rippling_representative,
        shipping_address: values.user_shipping_address,
        timestamp: formattedDate,
      };

      await fetch('/api/www-vercel-add-to-zapier', {
        body: JSON.stringify(zapierPayload),
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
      });
    };

    sendZapierData();
  }

  if (values.rtlid) {
    window.dataLayer.push({
      event: 'login',
      userId: values.rtlid,
    });
  }

  try {
    const savedFormData = cookies.get('saved_form_values');

    const dialCode =
      values.Country_Dialing_Code__c === 1
        ? '+1'
        : values.Country_Dialing_Code__c;

    var formData = {
      Append_to_Comments__c: Append_to_Comments__c || undefined,
      City: values.user_city || undefined,
      contact_reason: contact_reason && contact_reason.trim(),
      Country: values.Country || undefined,
      current_challenges_c: current_challenges_c?.join(';'),
      first_name: first_name && first_name.trim(),
      Form_Fill_Referring_URL__c: cookies.get('previous_url') || undefined,
      form_type: bodyFormData.get('Form_Type__c'),
      inboundTestVariant: bodyFormData.get('inboundTestVariant'),
      Industry: values.user_industry || undefined,
      initial_landing_page: bodyFormData.get('Landing_Page__c'),
      is_new_or_existing_customer: is_new_or_existing_customer || undefined,
      Last_Lead_Origin__c: Last_Lead_Origin__c ? Last_Lead_Origin__c.value : undefined,
      last_name: last_name && last_name.trim(),
      Likely_Global_Fit__c: Likely_Global_Fit__c || undefined,
      Medical_Renewal_Date__c_lead: insurance_date && insurance_date.trim(),
      Num_Years_as_Rippling_User__c: Num_Years_as_Rippling_User__c || undefined,
      Offer_Health_Insurance__c:
        company_offer_insurance && company_offer_insurance.trim(),
      organizationType: organizationType && organizationType.trim(),
      p_name: bodyFormData.get('p_name'),
      Product_Interest_3P__c: Product_Interest_3P__c || undefined,
      Product_Updates_Opt_Out__c: bodyFormData.get('Product_Updates_Opt_Out__c'),
      referrer: bodyFormData.get('referrer_url__c'),
      schedule_demo_date,
      schedule_demo_time,
      schedule_demo_timezone,
      State: State || undefined,
      Street: values.user_address || undefined,
      unityWebinarName: values.unityWebinarName?.join(';'),
      ur_products: ur_products?.join(';'),
      ur_topics: ur_topics?.join(';'),
      user_company: user_company && user_company.trim(),
      user_company_type: bodyFormData.get('Company_Type_Detail__c'),
      user_email: business_email && business_email.trim() || user_email && user_email.trim(),
      user_employees: user_employees && Number.parseInt(user_employees),
      user_gift_preference: user_gift_preference && user_gift_preference.trim(),
      user_lead_source: user_lead_source && user_lead_source.trim(),
      user_name: user_name && user_name.trim(),
      user_phone: dialCode
        ? `${dialCode} ${user_phone && user_phone.trim()}`
        : user_phone && user_phone.trim(),
      user_previous_payroll:
        user_previous_payroll && user_previous_payroll.trim(),
      user_title: user_title && user_title.trim(),
      utility: bodyFormData.get('utility'),
      video_tour: bodyFormData.get('video_tour'),
      Wavemakers_Application_Reason__c:
        Wavemakers_Application_Reason__c || undefined,
      Webinar_Registration_Date__c: bodyFormData.get('Webinar_Registration_Date__c'),
      who_referred_you: who_referred_you && who_referred_you.trim(),
      Zip_Postal_Code: values.user_zip_code || undefined,
    };

    // Forms that update existing records need all previous values
    // A growth reusable uses Last_Lead_Origin__c === Generic Multi-step form across URLs
    if ((
      [
        '/thank-you',
        'thank-you-c',
        '/resources/peo-quiz',
        '/shrm2023/confirmation',
        '/it-trial/thank-you',
      ].includes(routerPath) ||
      formData.Last_Lead_Origin__c === 'Generic Multi-step form' ||
      whitelistScheduleDate
    ) && savedFormData) {
      try {
        if (typeof savedFormData === 'object') {
          for (const key in formData) {
            const val = formData[key];
            const savedVal = savedFormData[key];

            if (!val && savedVal || key === 'Form_Fill_Referring_URL__c' || values?.enriched?.includes(key) && savedVal) {
              formData[key] = savedVal;
            }

            // Handle form data that is stored in hidden fields
            const formDataKey = formDataKeyToFieldNameMappings[key];
            const formDataValue = bodyFormData.get(formDataKey);

            if (formDataKey && !formDataValue && savedVal) {
              bodyFormData.set(formDataKey, savedVal);
            }
          }
        }
      } catch (error) {
        console.log('e:', error);
      }
    }
  } catch (error) {
    console.log('e:', error);
    onError();
  }

  let quiz_redirect;

  if (routerPath === '/resources/peo-quiz') {
    const user_employees =
      formData.user_employees && Number.parseInt(formData.user_employees);

    if (user_employees >= 100) {
      quiz_redirect = '2b';
    } else if (user_employees <= 4) {
      if (
        values.w2EmployeesNumOfStates === 'Yes' ||
        ['We-plan-to-hire-employees-in-more-than-one-state', 'We-plan-to-hire-employees-only-in-our-HQ'].includes(values.employeesGrowth6Months)
      ) {
        quiz_redirect = '1a';
      } else {
        quiz_redirect = '2a';
      }
    } else if (user_employees > 4 && user_employees <= 100) {
      if (values.employeesGrowth6Months === 'We-plan-to-downsize') {
        quiz_redirect = '2a';
      } else {
        quiz_redirect = '1a';
      }
    }
  }

  const { hostname } = window.location;

  try {
    var fullName = formData.user_name
      ? formData.user_name.split(' ')
      : [formData.first_name, formData.last_name];
    var refercookieInfo = cookies.get('rippling_refer_user');
    var aclidInfo = cookies.get('aclid');
    var gclidInfo = cookies.get('gclid');
    var fbclidInfo = cookies.get('fbclid');
    var fbpInfo = cookies.get('_fbp');
    var msclkidInfo = cookies.get('msclkid');
    var existingCustomer = cookies.get('ExistingCustomer');
  } catch {
    onError();
  }

  let classification_redirect;

  if (/^\/tools\/worker-classification-analyzer/.test(routerPath)) {
    const formValues = Object.keys(values);

    const answeredEmployeeArr = [];
    for (const value of formValues) {
      if (values[value].toString().includes('Employee')) {
        answeredEmployeeArr.push(values[value]);
      }
    }

    const userType = existingCustomer ? 'Customer' : 'Prospect';

    if (answeredEmployeeArr?.length >= 2) {
      classification_redirect = 'high-risk';
      formData.Last_Lead_Origin__c = `${formData.Last_Lead_Origin__c} High Risk (${userType})`;
    } else if (answeredEmployeeArr?.length === 1) {
      classification_redirect = 'moderate-risk';
      formData.Last_Lead_Origin__c = `${formData.Last_Lead_Origin__c} Moderate Risk (${userType})`;
    } else {
      classification_redirect = 'low-risk';
      formData.Last_Lead_Origin__c = `${formData.Last_Lead_Origin__c} Low Risk (${userType})`;
    }
  }

  const form_category = hiddenFieldValues.form_category;
  const isQuote = form_category === 'quote';

  const cbRevealData = getClearbitData();

  const utmRefacc = cookies.get('utm_refacc');
  const utmSource = cookies.get('utm_source');

  const currentUrl = getCurrentFormFillLandingPageUrl();

  addToFormData('formFillLandingPage', currentUrl);

  if (googleClientId) {
    addToFormData('GA4_Client_ID__c', googleClientId);
  }

  for (const [key, value] of Object.entries(chiliPiperFlowData)) {
    addToFormData(key, value);
  }

  addToFormData('Contact_ID', values.rtlid || undefined);
  addToFormData('Optimizely_Variation__c', getVisitorsExperiments());
  addToFormData('Email', formData.business_email || formData.user_email);
  addToFormData(
    'FirstName',
    fullName.length > 1 ? fullName.slice(0, -1).join(' ') : fullName[0]
  );
  addToFormData(
    'LastName',
    fullName.length > 1 ? fullName.slice(-1).join(' ') : '[[unknown]]'
  );
  addToFormData('Company', formData.user_company);
  addToFormData('NumberOfEmployees', formData.user_employees);
  addToFormData('Title', formData.user_title);
  addToFormData('ReferrerSource', formData.user_lead_source);
  addToFormData(
    'How_Did_You_Hear_About_Us_free_text__c',
    values.user_lead_source_text
  );
  addToFormData('Referring_Partner__c', formData.who_referred_you);
  addToFormData('LT_Campaign__c', cookies.get('utm_campaign'));
  addToFormData('LT_Content__c', cookies.get('utm_content'));
  addToFormData('LT_Medium__c', cookies.get('utm_medium'));
  addToFormData('LT_Source__c', utmSource);
  addToFormData('LT_Term__c', cookies.get('utm_term'));
  addToFormData('UTM Group', cookies.get('utm_group'));
  addToFormData('UTM ReferralAccount', utmRefacc);
  addToFormData('UTM Program', cookies.get('utm_program'));
  addToFormData('UTM Product', cookies.get('utm_product'));
  addToFormData('Product_Interest_3P__c', cookies.get('Product_Interest') || formData.Product_Interest_3P__c);
  addToFormData('Subscription Referrer', cookies.get('SubRef'));
  addToFormData(
    'Ambassador__Short_Code__c',
    refercookieInfo ? refercookieInfo.short_code : ''
  );
  addToFormData(
    'GetAmbassador__Short_Code__c',
    refercookieInfo ? refercookieInfo.short_code : ''
  );
  addToFormData('GCLID__c', gclidInfo || '');
  addToFormData('FBCLID__c', fbclidInfo || '');
  addToFormData('MSCLKID__c', msclkidInfo || '');
  addToFormData('FBP', fbpInfo || '');
  addToFormData('MVF_Click_ID__c', aclidInfo || '');

  addToFormData('user_agent', navigator.userAgent || '');
  addToFormData('visitor_ip', cbRevealData.ip);
  addToFormData('ga_session_id', getGASessionId({ gaSessionKey: process.env.NEXT_PUBLIC_GA_SESSION_KEY }));
  addToFormData('Has_Payroll__c', formData.user_previous_payroll || 'False');
  addToFormData('Gift_Choice__c', formData.user_gift_preference);
  addToFormData('request_quote', isQuote || '');
  addToFormData('Phone', formData.user_phone);
  addToFormData('Country', formData.Country);
  addToFormData(
    'Offer_Health_Insurance__c',
    formData.Offer_Health_Insurance__c
  );
  addToFormData(
    'Medical_Renewal_Date__c_lead',
    formData.Medical_Renewal_Date__c_lead
  );
  addToFormData('utilityBetaandResearchTopicText', formData.ur_topics);
  addToFormData('utilityBetaandResearchProductTextArea', formData.ur_products);
  addToFormData('unityWebinarName', formData.unityWebinarName);
  addToFormData(
    'utilityGiftCardIncentive',
    cookies.get('homepage_100dollar_exit_popup_seen')
  );
  addToFormData(
    'W2_employees_in_more_than_one_state__c',
    values.w2EmployeesNumOfStates
  );
  addToFormData(
    'Next_6_months_for_employee_growth__c',
    values.employeesGrowth6Months
  );
  addToFormData(
    'Offer_employees_medical_insurance__c',
    values.offerMedicalInsurance
  );
  addToFormData(
    'How_compliance_for_HR_is_handled__c',
    values.ensureComplianceWithPayrollLaws
  );
  addToFormData('Last_Lead_Origin__c', Last_Lead_Origin__c);
  addToFormData('Marketo_PEO_Quiz_Result__c', quiz_redirect);
  addToFormData('organizationType', formData.organizationType);
  addToFormData('Contact_Us_Reason__c', formData.contact_reason);
  addToFormData('Demo_Request_Meeting_Date__c', formData.schedule_demo_date);
  addToFormData('Demo_Request_Meeting_Time__c', formData.schedule_demo_time);
  addToFormData(
    'Demo_Request_Meeting_Time_Zone__c',
    formData.schedule_demo_timezone
  );
  addToFormData('current_challenges_c', formData.current_challenges_c);
  addToFormData(
    'Demo_PreQual_Areas_of_Interest__c',
    values.Demo_PreQual_Areas_of_Interest__c
  );
  addToFormData(
    'Demo_PreQual_Current_Payroll_Provider__c',
    values.Demo_PreQual_Current_Payroll_Provider__c
  );
  addToFormData(
    'Demo_PreQual_Num_and_Type_of_Employees__c',
    values.Demo_PreQual_Num_and_Type_of_Employees__c
  );
  addToFormData('Last_Lead_Origin__c', formData.Last_Lead_Origin__c);
  addToFormData(
    'Form_Fill_Referring_URL__c',
    formData.Form_Fill_Referring_URL__c
  );
  addToFormData(
    'Num_Years_as_Rippling_User__c',
    formData.Num_Years_as_Rippling_User__c
  );
  addToFormData(
    'Wavemakers_Application_Reason__c',
    formData.Wavemakers_Application_Reason__c
  );
  addToFormData('Append_to_Comments__c', formData.Append_to_Comments__c);
  addToFormData('State', formData.State);
  addToFormData(
    'is_new_or_existing_customer',
    formData.is_new_or_existing_customer
  );
  addToFormData('Likely_Global_Fit__c', formData.Likely_Global_Fit__c);
  addToFormData('City', formData.City);
  addToFormData('Industry', formData.Industry);
  addToFormData('Street', formData.Street);
  addToFormData('Zip_Postal_Code', formData.Zip_Postal_Code);
  addToFormData('demotimeEligible', enableDemotimeFlow);

  const eventKeys = ['form_fill'];

  let secondaryEventName = '';

  if (formData.Last_Lead_Origin__c === 'Demo Request - Exit Intent Modal') {
    secondaryEventName = 'exit_intent_modal';
  } else if (form_category === 'demo' || form_category === 'partner') {
    secondaryEventName = 'demo_request';
  } else if (form_category === 'quote') {
    secondaryEventName = 'quote_request';
  } else if (
    form_category === 'tour' &&
    window.location.pathname === '/thank-you'
  ) {
    secondaryEventName = 'demo_date_request';
  } else if (form_category === 'tour') {
    secondaryEventName = 'tour_request';
  } else if (form_category === 'content') {
    secondaryEventName = 'content_request';
  }

  eventKeys.push(secondaryEventName);

  const fieldsAsObject = formDataToObject(bodyFormData);
  const lastFormSubmit = cookies.get('last_form_submit');

  try {
    const formValuesFromSavaSessionStorage = window.sessionStorage.getItem('saved_form_values') ?? '{}';
    let formValuesFromSava;

    try {
      formValuesFromSava = JSON.parse(formValuesFromSavaSessionStorage);
    } catch (error) {
      formValuesFromSava = {};
      console.error('error:', error);
    }

    const finalPayload = Object.keys(formValuesFromSava).reduce((acc, key) => {
      acc[key] = acc[key] || formValuesFromSava[key];

      return acc;
    }, fieldsAsObject);

    await postToOpenpriseProxy({
      formData: finalPayload,
      lastFormSubmit,
    });
  } catch (error) {
    // @TODO: add Datadog alert
    console.error('Form submission error:', error);
  }

  cookies.set('last_form_submit', Date.now(), { expires: getXHoursFromNow(0.0833) });

  const excludedUtmSourcesForDemotime = ['broker-referral', 'accountant-referral'];

  /**
   * Speacial case for the demo time flow if the user is coming from a specific UTM params/values
   * https://rippling.atlassian.net/browse/WWW-938
   */
  if (utmRefacc && excludedUtmSourcesForDemotime.includes(utmSource)) {
    enableDemotimeFlow = false;
  }

  if (enableDemotimeFlow) {
    redirectTo = null;

    let didBookingSucceed = false;
    let resolveDemotimePromise = null;
    let demotimeEvents = {};

    const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const demotimeMessageHandler = (event) => {
      if (event.origin !== process.env.NEXT_PUBLIC_DEMOTIME_BASE_URL) {
        return;
      }

      // capture all events from demotime
      demotimeEvents = {
        ...demotimeEvents,
        ...event.data.payload,
      };

      // parse the availability-loaded event for back compatibility
      if (event.data.eventName === 'availability-loaded') {
        demotimeFlowPayload = {
          ...demotimeFlowPayload,
          didMeetingAvailabiltyLoad: true,
        };
      }

      if (event.data.eventName === 'booking-confirmed') {
        didBookingSucceed = true;

        const {
          assigneeEmail,
          assigneeId,
          assigneeUserId,
          meetingDuration,
          meetingEndDateTimeUTC,
          meetingId,
          meetingLocalTimezone,
          meetingStartDateTimeUTC,
          routingId,
          routingName,
        } = demotimeEvents;

        demotimeFlowPayload = {
          ...demotimeFlowPayload,
          ActivityDate: meetingStartDateTimeUTC,
          ActivityDateTime: meetingStartDateTimeUTC,
          ActivityDuration: meetingDuration,
          ActivityTimeZone: meetingLocalTimezone,
          AssigneeEmail: assigneeEmail, // new
          AssigneeId: assigneeId, // new
          clientTimeZone,
          demotimeEvents: JSON.stringify(demotimeEvents),
          DemotimeMeetingId: meetingId, // new
          EventEndDateTime: meetingEndDateTimeUTC,
          EventOwnerId: assigneeUserId,
          EventStartDateTime: meetingStartDateTimeUTC,
          Queue_Name_CP__c: routingId,
          Router_Name_CP__c: routingName,
        };

        resolveDemotimePromise();
      }

      if (['idle', 'close'].includes(event.data.eventName)) {
        setShowDemotimeModal(false);
        resolveDemotimePromise();
      }
    };

    window.addEventListener('message', demotimeMessageHandler);

    setShowDemotimeModal(true);
    document.body.style.overflow = 'hidden';

    await new Promise((resolve) => {
      resolveDemotimePromise = resolve;
    });

    setShowDemotimeModal(false);
    document.body.style.overflow = 'auto';

    window.removeEventListener('message', demotimeMessageHandler);

    if (didBookingSucceed) {
      redirectTo = '/thank-you-demo';

      const lastFormSubmit = cookies.get('last_form_submit');

      try {
        const demotimeFormPayload = {
          formData: {
            // regular form data
            ...fieldsAsObject,

            // demotime specific data
            ...demotimeFlowPayload,
          },
          lastFormSubmit,
        };

        await postToOpenpriseProxy(demotimeFormPayload);
      } catch (error) {
        // @TODO: add Datadog alert
        console.error('[Demotime] Form submission error:', error);
      }
    }

    if (isItFreeTrialDemotimeFormPage(router)) {
      redirectTo = '/lp/request-it-free-trial-thank-you';
    }
  }

  if (setIsComplete) {
    setIsComplete();
  }

  const formIdentifier = getFormIdentifier({
    currentUrl,
    formValues: fieldsAsObject,
  });

  window.dataLayer.push({
    event: 'Form Submit (Success)',
    formCategory: form_category,
    formCompanyName: formData.user_company,
    formFullName: fullName,
    formIdentifier,
    formLeadSource: formData.user_lead_source,
    formNumberOfEmployees: formData.user_employees,
    formType: formData.form_type,
    formWorkEmail: formData.user_email,
    formWorkPhone: formData.user_phone,
    sheduleDemoDate: formData.schedule_demo_date,
  });

  // Deprecate in favor of the 2nd
  if (customOnSuccess) {
    customOnSuccess();
  }

  if (customOnSuccess2) {
    new Function('values', customOnSuccess2)({
      cookies,
      formData,
      router,
    });
  }

  try {
    if (window.clearbit && window.clearbit.identify) {
      window.clearbit.identify(formData.user_email, { email: formData.user_email });
    }
  } catch (error) {
    console.log('e:', error);
  }

  cookies.set('thankyou_email', formData.user_email, {
    domain: window.location.hostname,
    expires: get30Days(),
    path: '/',
  });

  if (formData.Last_Lead_Origin__c === 'Generic Multi-step form') {
    cookies.set('saved_form_values', formData, {
      domain: hostname,
      expires: getXHoursFromNow(48),
      path: '/',
    });
  }

  if (formData.schedule_demo_date) {
    if ([
      '/thank-you',
      '/thank-you-c',
      '/it-trial/thank-you',
    ].includes(routerPath) || whitelistScheduleDate) {
      const scheduleMessage = document.querySelector('.scheduleMessage');
      const formStep = document.querySelector('form .multistepForm_step');

      formStep.classList.add('-hide');

      if (scheduleMessage) {
        // eslint-disable-next-line unicorn/prefer-dom-node-text-content
        scheduleMessage.innerText = getDemoDateTime(formData, { locale });
      }

      const scheduleMessageWrapper = document.querySelector('.scheduleMessage_wrapper');
      if (scheduleMessageWrapper) {
        scheduleMessageWrapper.classList.remove('hidden');
      }
    } else if (routerPath === '/shrm2023/confirmation') {
      const formStep = document.querySelector('form .multistepForm_step');

      formStep.classList.add('-hide');

      const scheduleMessageWrapper = document.querySelector('.scheduleMessage_wrapper');
      if (scheduleMessageWrapper) {
        scheduleMessageWrapper.classList.remove('hidden');
      }
    } else {
      const formStep = [...document.querySelectorAll('form .multistepForm_step')];
      for (const step of formStep) {
        step.classList.add('-hide');
      }

      const scheduleMessageWrapper = document.querySelector('.scheduleMessage_wrapper');
      if (scheduleMessageWrapper) {
        scheduleMessageWrapper.classList.remove('hidden');
      }
    }
  }

  const hrTech = routerPath === '/hr-tech';
  if (hrTech) {
    values = {
      company: formData.user_company,
      email: formData.user_email,
      firstname: formData.first_name,
      lastname: formData.last_name,
    };

    ChiliPiper.submit('rippling', 'hr-tech-field-event', {
      lead: values,
      map: true,
    });

    return false;
  }

  const isStorylaneForm = routerPath === '/interactive-tour';
  if (isStorylaneForm) {
    // eslint-disable-next-line no-undef
    launchStorylaneDemo();
  } else if (!turnOffThankYouPageRedirect) {
    if (quiz_redirect) {
      quiz_redirect = quiz_redirect === '1a' ? '1' : quiz_redirect;

      router.push(`/hr-quiz-results-${quiz_redirect}`);
    } else if (classification_redirect) {
      const path = existingCustomer
        ? `/tools/worker-classification-analyzer-${classification_redirect}`
        : `/tools/worker-classification-analyzer-results-${classification_redirect}`;

      router.push(path);
    } else {
      cookies.set('saved_form_values', formData, {
        domain: hostname,
        expires: getXHoursFromNow(48),
        path: '/',
      });
      router.push(redirectTo || '/thank-you', undefined, routerOptions);
    }
  }
};

const simpleSubmissionHandler = async (
  values,
  router,
  redirectTo,
  enableClearbitPeopleSearch,
  formRef
) => {
  const redirectURL = redirectTo || null;
  const cookies = new Cookies();
  captureUserEmail(cookies, values);

  if (enableClearbitPeopleSearch) {
    const halfMinuteOfAnHourFraction = 0.008_33;
    const cookieSettings = {
      expires: getXHoursFromNow(halfMinuteOfAnHourFraction),
      path: '/',
    };

    for (const [key, val] of Object.entries(values)) {
      if (!val) {
        continue;
      }

      cookies.set(`clearbit_${key}`, val, cookieSettings);
    }
  }

  const { pathname } = window.location;

  window.dataLayer.push({
    event: 'multistep_form_start',
    formCategory: 'simple',
    formType: pathname,
  });

  const doNotSendToOp = formRef.current?.querySelector('input[name="do_not_send_to_op"]');

  if (doNotSendToOp) {
    router.push(redirectURL || '/request-demo');

    return;
  }

  const cbRevealData = getClearbitData();
  const currentUrl = getCurrentFormFillLandingPageUrl();

  const formTypeHiddenEl = formRef.current?.querySelector('input[name="Form_Type__c"]');

  const formType = formTypeHiddenEl ? formTypeHiddenEl.value : undefined;

  const bodyFormData = new FormData();
  bodyFormData.append('Email', values?.user_email || values?.business_email);
  bodyFormData.append('p_name', 'simple-form');
  bodyFormData.append('Form_Type__c', formType);
  bodyFormData.append('visitor_ip', cbRevealData.ip);
  bodyFormData.append('ga_session_id', getGASessionId({ gaSessionKey: '_ga_5FVLVKX2DN' }));
  bodyFormData.append('formFillLandingPage', currentUrl);
  bodyFormData.append('Optimizely_Variation__c', getVisitorsExperiments());

  const fieldsAsObject = formDataToObject(bodyFormData);

  const lastFormSubmit = cookies.get('last_form_submit');

  // The following calls are not supposed to halt redirecting to the appropriate form.
  // If they fail, log the error to the console for debuggining purposes
  try {
    await postToOpenpriseProxy({
      formData: fieldsAsObject,
      lastFormSubmit,
    });
  } catch (error) {
    // @TODO: add Datadog alert
    console.error('Simple Form submission error:', error);
  }

  const formIdentifier = getFormIdentifier({
    currentUrl,
    formValues: fieldsAsObject,
  });

  window.dataLayer.push({
    event: 'Form Submit (Success)',
    formCategory: 'simple',
    formIdentifier,
    formType: pathname,
  });

  redirectTo = redirectURL || '/request-demo';

  if ([
    '/peo',
    '/peo-02',
    '/peo-03',
  ].includes(pathname)) {
    redirectTo = '/peo-request-demo';
  } else if (['/accountants', '/accountants-02'].includes(pathname)) {
    redirectTo = '/accountants-demo-request';
  } else if (['/brokers', '/brokers-02'].includes(pathname)) {
    redirectTo = '/brokers-demo-request';
  } else if (['/venture-firms-and-accelerators'].includes(pathname)) {
    redirectTo = '/venture-firms-and-accelerators-demo-request';
  } else if (['/developers'].includes(pathname)) {
    redirectTo = '/developers-partner-form';
  }

  router.push(redirectTo);
};

const handleIsPartnerPageListing = (fields, touched) => {
  const isPartnerPageListing =
    getUrlParam('utm_campaign') === 'partner_page_listing';

  if (isPartnerPageListing) {
    const utmMedium = getUrlParam('utm_medium');
    const utmSource = getUrlParam('utm_source');

    if (utmMedium) {
      const userLeadSource = {
        accountant: 'Accountant',
        broker: 'Insurance Broker',
      };

      fields.user_lead_source = userLeadSource[utmMedium];
      touched.user_lead_source = true;
    }

    if (utmSource) {
      const whoReferredYou = {
        'aduro-advisors': 'Aduro Advisors',
        aeis: 'AEIS Advisors',
        akw: 'AKW Financial',
        alium: 'Alium',
        alliant: 'Alliant',
        basis365: 'Basis 365',
        bdo: 'BDO',
        bookkeeper360: 'Bookkeeper360',
        capitolbenefits: 'Capitol Benefits',
        cfa: 'CFA Insurance',
        countsy: 'Countsy',
        finvisor: 'Finvisor',
        formationfinancial: 'Formation Financial',
        guideline: '',
        'kbi-benefits': 'KBI Consulting',
        'kruze-consulting': 'Kruze Consulting',
        launchways: 'Launchways',
        rfp: 'RFP',
      };

      fields.who_referred_you = whoReferredYou[utmSource];
      touched.who_referred_you = true;
    }
  }
};

function disableEnterSubmitOnKeyDown(keyEvent) {
  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
    keyEvent.preventDefault();
  }
}

const hideField = (child) => {
  const { name: fieldName } = child.props?.attributes || {};

  if (
    ![
      'user_email',
      'user_lead_source',
      'who_referred_you',
      'user_previous_payroll',
    ].includes(fieldName)
   && !/\shidden/.test(child.props.attributes?.className) && child.props.attributes?.className) {
    child.props.attributes.className += ' hidden';
  }
};

const showField = (child) => {
  if (child.props.attributes.className) {
    child.props.attributes.className = child.props.attributes?.className.replaceAll(
      /\shidden/g,
      ''
    );
  }
};

function parseFastTrackLocalesFromClassName(classNames) {
  if (!classNames) {
    return [];
  }

  const localeRegex = /([a-z]{2}-[A-Z]{2})/g;
  const prefix = 'use-fast-track-locales--';

  const found = classNames.split(' ').find((item) => item.startsWith(prefix));
  let matches = null;

  if (found) {
    matches = found.match(localeRegex);
  }

  return matches || [];
}

function isItFreeTrialDemotimeFormPage(router) {
  const url = new URL(router.asPath, window.location.origin);
  const pathname = url.pathname;

  return pathname === '/lp/paid-request-it-free-trial';
}

function useDemotimeFlow({ router, values }) {
  if (!process.env.NEXT_PUBLIC_DEMOTIME_BASE_URL) {
    return false;
  }

  if (isItFreeTrialDemotimeFormPage(router) && values.Country === 'United States') {
    return true;
  }

  if (!values.Country) {
    return false;
  }

  const url = new URL(router.asPath, window.location.origin);
  const pathname = url.pathname;

  const demotimeEnabledRoutes = ['/request-demo'];

  const demotimeEnabledCountries = [
    'United States',
    'United Kingdom',
    'Ireland',
  ];

  const isDemotimeEnabled = demotimeEnabledRoutes.includes(pathname);
  const isDemotimeCountry = demotimeEnabledCountries.includes(values.Country);
  const isDemotimeEmployeeSizeInRange = values.user_employees >= 1 && values.user_employees <= 1000;
  const isDemotimeFlow = isDemotimeEnabled && isDemotimeCountry && isDemotimeEmployeeSizeInRange;

  return isDemotimeFlow;
}

let formStarted = false;
let reportedClearbitRequestToGa = false;

class BasicForm extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    const cookies = new Cookies();

    // Hide headquarters if it is set from Clearbit
    this.countrySetFromClearbit = cookies.get('clearbit_Country');

    const [
      fields,
      touched,
      steps,
      dependencies,
    ] = getFields(
      this.props,
      {},
      {},
      [],
      [],
      this.props.attributes.enableClearbitEmailSearch
    );

    this.fields = fields;
    this.touched = touched;
    this.steps = steps;
    this.dependencies = dependencies;

    // The following 2 flags are used to stop a submit from happening on the simple form before the
    // async requests resolve on field blur. They will manually submit after resolution.
    this.isFetchingClearbitData = false;
    this.wasSubmittingForm = false;
    this.wasBlurringEmailField = false;
    this.emailBlurs = 0;

    this.state = {
      isComplete: false,
      loadClearbitUser: true,
      referrer: '',
      showDemotimeModal: false,
    };

    this.formRef = React.createRef();
  }

  componentDidMount() {
    handleIsPartnerPageListing(this.fields, this.touched);

    this.setState({ referrer: window.document.referrer });
  }

  componentWillUnmount() {}

  handleSubmit(e) {
    e.preventDefault();
  }

  render() {
    const {
      googleClientId,
      hasFunctionalConsent,
      rtlid,
    } = this.context;

    const {
      attributes, children, locale, router,
    } = this.props;

    const {
      className,
      customOnSuccess,
      customOnSuccess2,
      data,
      disableEnterSubmit,
      formType,
      initialValues,
      isSimpleSubmit,
      redirectTo,
      turnOffThankYouPageRedirect,
    } = attributes;
    const enableClearbitPeopleSearch = attributes.enableClearbitEmailSearch;
    const cookies = new Cookies();
    const initialLandingPage = cookies.get('initial_landing_page');
    const { isComplete, referrer } = this.state;

    const isGiftOptInFormPage = className?.includes('gift-optin-form');
    const enableGiftOptInDemoFlow = isGiftOptInFormPage;

    const isFastTrackFormPage = className?.includes('fast-track-demo-request-form');

    const fastTrackLocalesByClassName = parseFastTrackLocalesFromClassName(className);
    const isFastTrackLocale = ['en-CA', 'fr-CA'].includes(router.locale) || fastTrackLocalesByClassName.includes(router.locale);
    const enableFastTrackDemoFlow = isFastTrackFormPage && isFastTrackLocale;
    const isItFastTrack = className?.includes('is-fast-track-demo-request-form-type--it-trial');

    const isChilipiperEnabled = className?.includes('is-chilipiper-enabled');
    const enableChiliPiperDemoFlow = ['en-US'].includes(router.locale) && isChilipiperEnabled && hasFunctionalConsent;
    const enablePreferenceCenterFlow = className?.includes('preference-center-form');
    const enableUnsubscribeEmailFlow = className?.includes('unsubscribe-form');

    const whitelistScheduleDate = className?.includes('schedule-date-form');

    let enableRtlidFormFlow = false;

    if (rtlid !== null) {
      if (router.asPath.includes('/resources') || className?.includes('is-rtlid-enabled')) {
        enableRtlidFormFlow = true;
      }

      if (className?.includes('is-rtlid-disabled')) {
        enableRtlidFormFlow = false;
      }
    }

    return (
      <Formik
        initialTouched={this.touched}
        initialValues={
          initialValues
            ? Object.assign(this.fields, initialValues)
            : this.fields
        }
        onSubmit={
          isSimpleSubmit
            ? (values) => {
              if (this.isFetchingClearbitData) {
                this.wasSubmittingForm = true;

                return;
              }

              simpleSubmissionHandler(
                values,
                this.props.router,
                redirectTo,
                enableClearbitPeopleSearch,
                this.formRef
              );
            }
            : async (values) => {
              const enableDemotimeFlow = useDemotimeFlow({
                router: this.props.router,
                values,
              });

              await submissionHandler({
                customOnSuccess,
                customOnSuccess2,
                enableChiliPiperDemoFlow,
                enableDemotimeFlow,
                enableFastTrackDemoFlow,
                enableGiftOptInDemoFlow,
                enablePreferenceCenterFlow,
                enableUnsubscribeEmailFlow,
                formRef: this.formRef,
                googleClientId,
                locale,
                redirectTo,
                router: this.props.router,
                setIsComplete: () => this.setState({ isComplete: true }),
                setShowDemotimeModal: (showDemotimeModal) => this.setState({ showDemotimeModal }),
                turnOffThankYouPageRedirect,
                values,
                whitelistScheduleDate,
              });
            }
        }
        validate={enableRtlidFormFlow ? null :
          (values, step) => validate(values, step, isItFastTrack)}
        validateOnChange={true}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          setValues,
          submitForm,
          touched,
          values,
        }) => {
          const [activeIndex, setActiveIndex] = useState(0);
          const [emailCheckedByClearbit, setEmailCheckedByClearbit] = useState(values.user_email);
          const [emailBlurs, setEmailBlurs] = useState(this.emailBlurs);
          const [isFormUsingRtlid, setIsFormUsingRtlid] = useState(false);

          //const [this.showDemotimeModal, setthis.ShowDemotimeModal] = useState(false);

          const clearbitKeys = {};
          const clearbitEnrichment = async (val) => {
            this.isFetchingClearbitData = true;
            handleChange(val);

            await throttledFetchUserFormData(val, (clearbitData) => {
              // Unfortunately we need a state update to set the isFetchingClearbitData flag to false
              // after a failed clearbit call
              if (!clearbitData) {
                setValues({ ...values });

                return;
              }
              if (!reportedClearbitRequestToGa) {
                reportedClearbitRequestToGa = true;

                for (const clearbitKey of Object.keys(clearbitData)) {
                  clearbitKeys[clearbitKey] = true;
                }
                window.dataLayer.push({
                  event: 'clearbit_form_enrichment',
                  ...clearbitKeys,
                });
              }

              if (clearbitData.Country) {
                this.countrySetFromClearbit = true;
              }

              setValues({
                ...values,
                ...clearbitData,
                enriched: Object.keys(clearbitData),
              });
            });
          };

          useEffect(() => {
            this.isFetchingClearbitData = false;

            if (this.wasSubmittingForm) {
              submitForm();
            }

            if (this.wasBlurringEmailField) {
              setEmailCheckedByClearbit(true);
            }
          }, [values]);

          useEffect(() => {
            const fn = async () => {
              if (cookies.get('user_email')) {
                await clearbitEnrichment(cookies.get('user_email'));
                setEmailCheckedByClearbit(true);
              }
            };

            fn();

            if (enableRtlidFormFlow) {
              setIsFormUsingRtlid(true);
              setValues({
                ...values,
                rtlid,
              });
            }
          }, []);

          useEffect(() => {
            const urlParams = new URLSearchParams(window.location.search);
            const emailParam = urlParams.get('Email');
            const firstName = urlParams.get('FirstName');
            const lastName = urlParams.get('LastName');
            const company = urlParams.get('Company');
            const fullName = `${firstName || ''} ${lastName || ''}`.trim();

            if (emailParam || firstName || lastName || company) {
              const paramValues = {
                first_name: firstName || undefined,
                last_name: lastName || undefined,
                user_company: company || undefined,
                user_email: emailParam || undefined,
                user_name: fullName || undefined,
              };

              setValues({
                ...values,
                ...paramValues,
              });
            }
          }, []);

          useEffect(() => {
            const storedFastTrackUser = JSON.parse(sessionStorage.getItem('fasttrack-questionnaire'));

            if (storedFastTrackUser !== null) {
              const {
                companyName,
                countryCode,
                email,
                firstName,
                lastName,
                nationalNumber,
                questionnaireTextBlob,
              } = storedFastTrackUser.data;

              const fullName = `${firstName || ''} ${lastName || ''}`.trim();
              const updatedCountryCode = `+${countryCode}`;
              const fastTrackValues = {
                Append_to_Comments__c: questionnaireTextBlob ?? null,
                Country_Dialing_Code__c: updatedCountryCode ?? null,
                first_name: firstName || null,
                last_name: lastName || null,
                user_company: companyName ?? null,
                user_email: email ?? null,
                user_name: fullName ?? null,
                user_phone: nationalNumber ?? null,
              };

              setValues({
                ...values,
                ...fastTrackValues,
              });
            }
          }, []);

          useEffect(() => {
            if (!enableChiliPiperDemoFlow) {
              return;
            }

            const script = document.createElement('script');
            script.src = 'https://js.chilipiper.com/marketing.js';
            script.async = true;
            document.body.append(script);

            const inlineScript = document.createElement('script');
            inlineScript.innerHTML = `
              function q(a) { return function () { ChiliPiper[a].q = (ChiliPiper[a].q || []).concat([arguments]) } }
              window.ChiliPiper = window.ChiliPiper || "submit scheduling showCalendar submit widget bookMeeting".split(" ").reduce(function (a, b) { a[b] = q(b); return a }, {});
            `;
            document.body.append(inlineScript);

            return () => {
              script.remove();
              inlineScript.remove();
            };
          }, [enableChiliPiperDemoFlow]);

          const isMultistep = this.steps.length > 0;

          if (
            isComplete &&
            isMultistep &&
            activeIndex === this.steps.length - 2
          ) {
            setActiveIndex(activeIndex + 1);
          }

          const childrenWithProps = recursiveMap(children, (child, i) => {
            let props;

            if (enableRtlidFormFlow) {
              const isSubmitButton = child.props.name === 'submit';
              const isHiddenField = child?.props?.attributes?.type === 'hidden';

              if (!isSubmitButton && !isHiddenField && rtlid) {
                child.props.attributes.className += ' hidden';
              }
            }

            if (child.props.attributes?.alias === 'Step') {
              props = {
                ...props,
                ...child.props,
                attributes: {
                  ...child.props.attributes,
                  data: { 'data-active': i === activeIndex || undefined },
                },
              };
            }

            if (/form_next/.test(child.props.attributes?.className)) {
              // Check if the current element (Next Button) also has the "step-partially-complete" class
              const hasStepPartiallyComplete = /step-partially-complete/.test(child.props.attributes?.className);
              const action = hasStepPartiallyComplete ? 'some' : 'every';
              const everyStepTouched = this.steps[activeIndex][
                action
              ]((field) => {
                return Array.isArray(values[field])
                  ? values[field].length
                  : values[field];
              });

              const isClickable =
                everyStepTouched &&
                !Object.keys(errors).some((error) =>
                  this.steps[activeIndex].find((field) => field === error));
              props = {
                ...child.props,
                attributes: {
                  ...child.props.attributes,
                  data: { disabled: !isClickable || undefined },
                },
                propsOnClick: () => {
                  if (isClickable) {
                    setActiveIndex(activeIndex + 1);
                  }
                },
              };
            }

            if (/form_prev/.test(child.props.attributes?.className)) {
              props = {
                ...child.props,
                propsOnClick: () => {
                  setActiveIndex(activeIndex - 1);
                },
              };
            }

            if (/form_progress/.test(child.props.attributes?.className)) {
              props = {
                ...child.props,
                attributes: {
                  ...child.props.attributes,
                  data: {
                    style: {
                      transform: `scaleX(${
                        isSubmitting || isComplete
                          ? 1
                          : activeIndex / this.steps.length
                      })`,
                    },
                  },
                },
              };
            }

            if (/form_indicator/.test(child.props.attributes?.className)) {
              const data = { 'data-active': true };

              for (let index = 0; index < activeIndex; index++) {
                data[`data-active${index + 2}`] = true;
              }

              props = {
                ...child.props,
                attributes: {
                  ...child.props.attributes,
                  data,
                },
              };
            }

            if (
              /form_success_message/.test(child.props.attributes?.className)
             && isComplete) {
              props = {
                ...child.props,
                attributes: {
                  ...child.props.attributes,
                  innerContent: child.props.attributes.innerContent.replace(
                    ':',
                    `: ${cookies.get('thankyou_email')}`
                  ),
                },
              };
            }

            if (child.props.name === 'submit') {
              const isFormPartiallyComplete = /form-partially-complete/.test(child.props.attributes?.className);

              const everyFieldTouched = Object.keys(values).every((key) => touched[key]);

              const everyFieldHasValues = Object.keys(values).every((key) => {
                return Array.isArray(values[key]) ? values[key].length : values[key];
              });

              // New logic to handle the step-partially-complete step
              const atLeastOneFieldTouched = Object.keys(values).some((key) => touched[key]);

              const isClickable = isFormPartiallyComplete
                ? atLeastOneFieldTouched && Object.keys(errors).length === 0 // At least one field touched for step-partially-complete, no errors
                : everyFieldTouched && Object.keys(errors).length === 0 ||
                  everyFieldHasValues; // All fields touched, no errors, or all fields have values for other steps

              props = {
                ...child.props,
                attributes: {
                  ...child.props.attributes,
                  data: {
                    disabled:
                      isSubmitting ||
                      this.steps.length > 2 && !isClickable ||
                      undefined,
                  },
                },
              };
            }

            if (
              [
                'radios',
                'field',
                'field2',
                'select',
                'date-picker',
              ].includes(child.props.name)
            ) {
              props = {
                errors,
                formRef: this.formRef,
                handleBlur,
                handleChange,
                isActive: this.stepIndex === this.activeIndex,
                setValues,
                touched,
                values,
              };

              const { name: fieldName } = child.props?.attributes || {};

              if (enableClearbitPeopleSearch) {
                if (['business_email', 'user_email'].includes(fieldName)) {
                  props.handleChange = async (val) => {
                    await clearbitEnrichment(val);
                  };

                  props.handleBlur = async (val) => {
                    this.wasBlurringEmailField = true;
                    handleBlur(val);

                    if (!this.isFetchingClearbitData) {
                      setEmailCheckedByClearbit(true);
                    }

                    // increment the number of email blurs
                    setEmailBlurs(emailBlurs + 1);
                  };
                }

                // Default fields to show + already hidden conditional fields
                hideField(child);

                if (emailCheckedByClearbit && (
                  values[fieldName] &&
                      !values.enriched?.includes(fieldName) ||
                    clearbitKeys[fieldName] === false ||
                    emailBlurs > 1 ||

                    // If user_phone is not set, show Country_Dialing_Code__c even if it's filled. For
                    // all other fields, show the field if it is not shown
                    ['Country_Dialing_Code__c'].includes(fieldName) &&
                      !values.user_phone ||
                    !['Country_Dialing_Code__c'].includes(fieldName) &&
                      !values[fieldName] ||

                    // Only hide Country if it is set by Clearbit. Show if set by user's geo
                    fieldName === 'Country' && !this.countrySetFromClearbit
                )) {
                  showField(child);
                }
              }

              // sometimes we use radios as buttons... AND use them to trigger form_next
              if (/form_next/.test(child.props.attributes?.className)) {
                const handleFormNextOnChange = (val) => {
                  if (handleChange) {
                    handleChange(val);
                  }
                  setActiveIndex(activeIndex + 1);
                };
                props.handleChange = handleFormNextOnChange;

                // we use this prop to signal that the radio set is used as a button and one should always appear as a "default" option.
                props.renderedAsFormButtons = true;
              }
            }

            if (React.isValidElement(child)) {
              return React.cloneElement(child, props);
            }

            return child;
          });

          const isError = Object.keys(touched).find((key) => errors[key]);

          if (isError) {
            let formTypeC = this.props.attributes.hiddenInputs?.Form_Type__c;
            formTypeC =
              formType === 'simple' ? window.location.pathname : formTypeC;

            window.dataLayer.push({
              event: 'form_error',
              formCategory: formType,
              formType: formTypeC,
            });
          }

          return (
            <>
              {this.state.showDemotimeModal && (
                <>
                  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center" style={{ zIndex: 9999 }}>
                    <div
                      className="max-w-800 w-full mx-4 overflow-hidden"
                      style={{
                        maxHeight: '95vh',
                        minHeight: '85vh',
                      }}
                    >
                      <iframe
                        src={(() => {
                          if (isItFreeTrialDemotimeFormPage(router)) {
                            values.Country = 'IT_Free_Trial';
                          }

                          const params = mapFormValuesToDemotime(values);
                          const demotimeParams = createDemotimeQueryStringFromObject(params);

                          const isSandboxUrl = process.env.NEXT_PUBLIC_DEMOTIME_BASE_URL.includes('uat');
                          const url = `${process.env.NEXT_PUBLIC_DEMOTIME_BASE_URL}/${isSandboxUrl ? 'meetings/' : ''}embedded-queue?${demotimeParams}`;

                          return url;
                        })()}
                        style={{
                          border: 'none',
                          height: '100%',
                          left: 0,
                          position: 'absolute',
                          top: 0,
                          width: '100%',
                        }}
                        title="Embedded Queue"
                      />
                    </div>
                  </div>
                </>
              )}
              <form
                ref={this.formRef}
                className={`form ${classnames({
                  '-error': Object.keys(errors).length > 0 && !enableRtlidFormFlow,
                  [className]: className,
                  'w-fit max-w-fit': isFormUsingRtlid,
                })}`}

                {...data}
                onClick={() => {
                  let formTypeC = this.props.attributes.hiddenInputs
                    ?.Form_Type__c;
                  formTypeC =
                  formType === 'simple' ? window.location.pathname : formTypeC;

                  if (!formStarted) {
                    window.dataLayer.push({
                      event: 'form_started',
                      formCategory: formType,
                      formType: formTypeC,
                    });

                    formStarted = true;
                  }
                }}
                onKeyDown={
                  disableEnterSubmit ? disableEnterSubmitOnKeyDown : undefined
                }
                onSubmit={(e) => {
                  e.preventDefault();
                  submitForm();
                }}
              >
                <HiddenFields
                  formType={formType}
                  hiddenInputs={this.props.attributes?.hiddenInputs}
                  initialLandingPage={initialLandingPage}
                  referrer={referrer}
                />
                {childrenWithProps}
              </form>
            </>
          );
        }}
      </Formik>
    );
  }
}

export default withRouter(BasicForm);
